* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}
h1,
h2,
h3 {
	color: rgb(54, 54, 54);
}
h3,
h4 {
	font-weight: 400;
	color: rgb(100, 100, 100);
}
.app {
	transition: all 0.5s ease;
}
.library-active {
	margin-left: 30%;
}
body {
	font-family: "Lato", sans-serif;
}
@import "./lib-nav";
@import "./library";
@import "./song";
@import "./player";
